import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
//componentes
import "./reporteCitas.css";
import Alert from "../../modal/alert";
import PublicarNotas from "../publicarNotas/index";

class AutorizarBoletinAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      desde: moment().format("YYYY-MM-DD"),
      hasta: moment().format("YYYY-MM-DD"),
      numeroEstatusR: "",
      ordenar: "",
      casos1: "",
      paciente1: "",
      iestatus: [],
      imedicos: [],
      icasos: [],
      listaOn: false,
      alumno:'',
      cedulaAlumno:'',
      medico:'',
      sucursal:'',
    };
  }

  limpiar = () => {
    this.setState({
      desde: moment().format("YYYY-MM-DD"),
      hasta: moment().format("YYYY-MM-DD"),
      numeroEstatusR: "",
      ordenar: "",
      casos1: "",
      paciente1: "",
      imedicos: [],
      listaOn: false,
      alumno:'',
      cedulaAlumno:'',
      medico:'',
      sucursal:'',

    });
  };
  onChange(e) {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "casos1") {
      if (e.target.value === "") {
        this.setState({
          imedicos: [],
        });
      } else {
        this.terapeutasDisponibles(e.target.value);
      }
    }
  }

  terapeutasDisponibles = (e) => {
    let data = new FormData();
    data.append("idespecialidad", e);
    data.append("boton", "consultaTerapeutasEspecialista");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximedicos = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            medicos: "",
            imedicos: [],
            imedicos: ximedicos,
          });
        } else {
          this.setState({
            medicos: "",
            imedicos: [],
          });
        }
      });
  };
  asociarEvaluacion = () => {
    let data = new FormData();
    data.append("boton", "Consultar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xcasos = res.data;
        this.setState({
          icasos: xcasos,
        });
      });
  };
  buscarPlanilla = () => {
    this.buscarPorNombres();
  };
  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.paciente1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Paciente con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable143 === "1") this.setState({ cumpleOn: true });
            if (res.data.variable129 === "MADRE") {
              this.setState({
                paciente1: res.data.variable1 + " " + res.data.variable2,
                cedulaAlumno: res.data.variable4,
                listaOn:false,
              
              });
              //this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                paciente1: res.data.variable1 + " " + res.data.variable2,
                cedulaAlumno: res.data.variable4,
                listaOn:false,
              });
              //this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Reporte de Citas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };
  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  componentDidMount() {
    this.asociarEvaluacion();
    // this.cargarFechas();
    // this.buscarAnioEscolar();
  }
  imprimir = () => {
    if (this.state.desde.length > 0 && this.state.hasta.length > 0) {
     
      let data = new FormData();
      data.append("desde", this.state.desde);
      data.append("hasta", this.state.hasta);
      data.append("estatus", this.state.numeroEstatusR);
      if (this.state.paciente1.length>0)data.append("cedulaAlumno", this.state.cedulaAlumno);
      if (this.state.paciente1.length===0)data.append("cedulaAlumno", '');
      data.append("orden", this.state.ordenar);
      data.append("caso", this.state.casos1);
      data.append("medico", this.state.medico);
      data.append("sucursal", this.state.sucursal);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteCitas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar las fechas Desde Hasta para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>REPORTE DE CITAS.</p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.listaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Listado de Pacientes</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.cerrarLista.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.nalumno.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consulta.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable2}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="fechas1 col-12">
            <div className="contenedorcedula1f col-3">
              <label className="titulo-desde">
                Fecha Desde.
                <input
                  className="form-control"
                  type="date"
                  name="desde"
                  id="desde"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.desde}
                />
              </label>
            </div>
            <div className="contenedorcedula1f col-3">
              <label className="titulo-hasta">
                Fecha Hasta.
                <input
                  className="form-control"
                  type="date"
                  name="hasta"
                  id="hasta"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.hasta}
                />
              </label>
            </div>

            <div className="contenedorcedula1f col-6">
              <select
                id="numeroEstatusR"
                name="numeroEstatusR"
                value={this.state.numeroEstatusR}
                onChange={this.onChange.bind(this)}
              >
                <option>-Seleccione Estatus-</option>
                <option value="100">Todos</option>
                <option value="1">Sin Confirmar</option>
                <option value="2">Confirmado</option>
                <option value="3">En Consulta</option>
                <option value="4">Cobrado</option>
                <option value="5">Anulado (No asistirá más)</option>
                <option value="6">No Asistio</option>
                <option value="7">En Espera</option>
                <option value="8">No Cobrado</option>
                <option value="9">Donación</option>
                <option value="10">Cancelado</option>
                <option value="11">Cambio</option>
                <option value="12">Factura Pendiente</option>
              </select>
            </div>
          </div>
          <div className="fechas2 col-12">
            <div className="contenedorcedula1f col-6">
              <select
                id="sucursal"
                name="sucursal"
                value={this.state.sucursal}
                onChange={this.onChange.bind(this)}
              >
                <option>-Seleccione Sucursal-</option>
                <option value="1">Bella Vista</option>
                <option value="2">Arraijan</option>
                <option value="3">Chorrera</option>
              </select>
            </div>
            <div className="contenedorcedula1f col-6">
              <select
                id="casos1"
                name="casos1"
                value={this.state.casos1}
                onChange={this.onChange.bind(this)}
              >
                <option value="">-Seleccione Especialización-</option>
                <option value="100">Todos</option>
                {this.state.icasos.map((item, i) => {
                  return (
                    <option
                      className={item.variable3 === 1 ? "dispo1" : ""}
                      key={i}
                      value={item.variable1}
                    >
                      {item.variable2}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="fechas2 col-12">
            <div className="contenedorcedula1f col-6">
              <select
                id="ordenar"
                name="ordenar"
                value={this.state.ordenar}
                onChange={this.onChange.bind(this)}
              >
                <option>-Seleccione Tipo de Orden-</option>
                <option value="1">Por Fecha</option>
                <option value="2">Por Fecha (Descendente)</option>
                <option value="3">Por Nombre</option>
                <option value="4">Por Nombre (Descendente)</option>
              </select>
            </div>
            <div className="contenedorcedula1f col-6">
              <select
                id="medico"
                name="medico"
                value={this.state.medico}
                onChange={this.onChange.bind(this)}
              >
                <option>-Seleccione Especialista-</option>
                <option value="100">Todos</option>
                {this.state.casos1.length > 0 &&
                  this.state.imedicos.map((item, i) => {
                    return (
                      <option
                        className={item.variable3 === 1 ? "dispo1" : ""}
                        key={i}
                        value={item.variable1}
                      >
                        {item.variable2}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="fechas2 col-5">
            <input
              className="form-control"
              type="text"
              name="paciente1"
              id="paciente1"
              autoComplete="off"
              placeholder="Nombre Paciente."
              maxLength="300"
              onChange={this.onChange.bind(this)}
              value={this.state.paciente1.toUpperCase()}
            />
            <button className="boton" onClick={this.buscarPlanilla.bind(this)}>
              Buscar Paciente
            </button>
          </div>
          <div className="fechas2 col-12">
            <button
              className="boton"
              onClick={this.imprimir.bind(this)}
            >
              Imprimir Reporte
            </button>
            <button
              className="boton"
              onClick={this.limpiar.bind(this)}
            >
              Limpiar
            </button>
          {/* </div>
          <div className="fechas2 col-12"> */}
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(AutorizarBoletinAction);
