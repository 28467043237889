import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import FeatherIcon from "feather-icons-react";
//import { TimeField } from "@mui/x-date-pickers/TimeField";
//componentes
import "./nomina.css";
import Alert from "../../modal/alert";

function Empleados(props) {
  const [listaOn, setlistaOn] = React.useState(false);
  const [listaTnOn, setlistaTnOn] = React.useState(false);
  const [listaSuOn, setlistaSuOn] = React.useState(false);
  const [listaDatosBOn, setlistaDatosBOn] = React.useState(false);

  const [personal, setpersonal] = React.useState([]);
  const [nominaP, setnominaP] = React.useState([]);
  const [nominaPS, setnominaPS] = React.useState([]);
  const [cuentaC, setcuentaC] = React.useState([]);
  const [horario, setHorario] = React.useState([]);
  const [activarHOn, setActivarHOn] = React.useState(false);
  const [btnnoauto2On, setbtnnoauto2On] = React.useState("NO");
  const [btnnoauto1On, setbtnnoauto1On] = React.useState("NO");
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [paso, setpaso] = React.useState(false);
  const [irutas, setirutas] = React.useState([]);
  const [mensaje, setmensaje] = React.useState("");

  const [horaE1, setHoraE1] = React.useState("");
  const [horaS1, setHoraS1] = React.useState("");
  const [horaE2, setHoraE2] = React.useState("");
  const [horaS2, setHoraS2] = React.useState("");
  const [horaTS1, setHoraTS1] = React.useState("");

  const [tiempoE1, setTiempoE1] = React.useState("");
  const [tiempoE2, setTiempoE2] = React.useState("");
  const [tiempoE3, setTiempoE3] = React.useState("");
  const [tiempoE4, setTiempoE4] = React.useState("");
  const [tiempoE5, setTiempoE5] = React.useState("");
  const [tiempoE6, setTiempoE6] = React.useState("");

  const [tiempoS1, setTiempoS1] = React.useState("");
  const [tiempoS2, setTiempoS2] = React.useState("");
  const [tiempoS3, setTiempoS3] = React.useState("");
  const [tiempoS4, setTiempoS4] = React.useState("");
  const [tiempoS5, setTiempoS5] = React.useState("");
  const [tiempoS6, setTiempoS6] = React.useState("");

  const [lunesCheck, setLunesCheck] = React.useState("");
  const [martesCheck, setMartesCheck] = React.useState("");
  const [miercolesCheck, setMiercolesCheck] = React.useState("");
  const [juevesCheck, setJuevesCheck] = React.useState("");
  const [viernesCheck, setViernesCheck] = React.useState("");
  const [sabadoCheck, setSabadoCheck] = React.useState("");
  const [fijoCheck, setFijoCheck] = React.useState("");

  const [loading, setloading] = React.useState(false);
  const [activarOn, setactivarOn] = React.useState(false);
  const [aprobarOn, setaprobarOn] = React.useState(false);
  const [trimestre, settrimestre] = React.useState(0);
  const [anopagar, setanopagar] = React.useState("");
  const [mesapagar, setmesapagar] = React.useState("");
  const [anio, setanio] = React.useState([]);
  const [mes, setmes] = React.useState([]);

  const [data, setData] = React.useState({
    cedula: "",
    cedulaOt: "",
    nombre: "",
    profesion: "",
    cargo: "",
    fechaIngreso: "",
    fechaEgreso: "",
    tiponomina: "",
    tiponominaA: "",
    status: "",
    statusA: "",
    statusDe: "",
    correo: "",
    fechaNaci: "",
    edad: "",
    direccion: "",
    nombreD1: "",
    edadD1: "",
    parentescoD1: "",
    nombreD2: "",
    edadD2: "",
    parentescoD2: "",
    nombreD3: "",
    edadD3: "",
    parentescoD3: "",
    nacionalidad: "",
    telefono: "",
    edocivil: "",
    cuenta: "",
    banco1: "",
    tcuenta: "",
    titular: "",
    horario: "",
    tcontrato: "",
    aprobado: "",
    salarioBaseMensual: "",
    horasDiaria: "",
    diasSemanal: "",
    salarioHora: "",
    salarioDia: "",
    salarioQuincenal: "",
    sso: "9.75",
    ssoDecimo: "7.25",
    seguroEducativo: "1.25",
    cuentaContable2: "",
  });
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");
  const [xsso, setxsso] = React.useState("9.75");
  const [xssoDecimo, setxssoDecimo] = React.useState("7.25");
  const [xseguroEducativo, setxseguroEducativo] = React.useState("1.25");

  const limpiar = () => {
    setmensajealerta(false);
    setactivarOn(false);
    let data_ = Object.assign({}, data);
    data_ = {
      sso: "9.75",
      ssoDecimo: "7.25",
      seguroEducativo: "1.25",
    };
    setData(data_);
  };

  const cerrarLista = () => {
    //console.log(listaOn)
    if (listaOn) {
      setlistaOn(false);
    }
    if (!listaOn) {
      setlistaOn(true);
      buscarPersonalC();
    }
  };

  const cerrarListaTn = () => {
    //console.log(listaOn)
    if (listaTnOn) {
      setlistaTnOn(false);
    }
    if (!listaTnOn) {
      setlistaTnOn(true);
    }
  };

  const cerrarListaSu = () => {
    //console.log(listaOn)
    if (listaSuOn) {
      setlistaSuOn(false);
    }
    if (!listaSuOn) {
      setlistaSuOn(true);
    }
  };

  const activarH = () => {
    //console.log(listaOn)
    if (activarHOn) {
      setActivarHOn(false);
    }
    if (!activarHOn) {
      setActivarHOn(true);
    }
  };

  const cerrarListaDatosB = () => {
    //console.log(listaOn)
    if (listaDatosBOn) {
      setlistaDatosBOn(false);
    }
    if (!listaDatosBOn) {
      setlistaDatosBOn(true);
    }
  };

  const onChange = (e) => {
    let index = e.target.getAttribute("id");
    let data_ = Object.assign({}, data);
    data_[index] = e.target.value;
    setData(data_);

    if (index === "trimestre") {
      settrimestre(e.target.value);
    }
    if (index === "anopagar") {
      setanopagar(e.target.value);
    }
    if (index === "mesapagar") {
      setmesapagar(e.target.value);
    }
  };

  const onChange1 = (e) => {
    let index = e.target.getAttribute("id");

    if (e.target.checked) {
      if (index === "lunesCheck") {
        setLunesCheck("SI");
      }
      if (index === "martesCheck") {
        setMartesCheck("SI");
      }
      if (index === "miercolesCheck") {
        setMiercolesCheck("SI");
      }
      if (index === "juevesCheck") {
        setJuevesCheck("SI");
      }
      if (index === "viernesCheck") {
        setViernesCheck("SI");
      }
      if (index === "sabadoCheck") {
        setSabadoCheck("SI");
      }
      if (index === "fijoCheck") {
        setFijoCheck("SI");
      }
    } else {
      if (index === "lunesCheck") {
        setLunesCheck("NO");
      }
      if (index === "martesCheck") {
        setMartesCheck("NO");
      }
      if (index === "miercolesCheck") {
        setMiercolesCheck("NO");
      }
      if (index === "juevesCheck") {
        setJuevesCheck("NO");
      }
      if (index === "viernesCheck") {
        setViernesCheck("NO");
      }
      if (index === "sabadoCheck") {
        setSabadoCheck("NO");
      }
      if (index === "fijoCheck") {
        setFijoCheck("");
      }
    }
  };

  const onChange2 = (e) => {
    let index = e.target.getAttribute("id");
    let data_ = Object.assign({}, data);
    data_[index] = e.target.value;
    setData(data_);

    buscarAsigDedu(e.target.value);
  };

  const onChange3 = (e) => {
    //console.log(e.target.getAttribute("id"));
    if (e.target.getAttribute("id") === "horaE1") {
      setHoraE1(e.target.value);
    }
    if (e.target.getAttribute("id") === "horaE2") {
      setHoraE2(e.target.value);
    }
    if (e.target.getAttribute("id") === "horaTS1") {
      setHoraTS1(e.target.value);
    }

    if (e.target.getAttribute("id") === "horaS1") {
      setHoraS1(e.target.value);
      // if (e.target.value.substring(0, 1) === "1") {
      //   setHoraS1(e.target.value);
      // } else {
      //   setHoraS1("");
      // }
    }
    if (e.target.getAttribute("id") === "horaS2") {
      setHoraS2(e.target.value);
      // if (e.target.value.substring(0, 1) === "1") {
      //   setHoraS2(e.target.value);
      // } else {
      //   setHoraS2("");
      // }
    }

    //console.log(e.target.name)
    //setopenvio(e.target.value);
    //console.log(e.target.value)
  };

  const handleClose = () => {
    setmensajealerta(false);
  };
  const botonnoautorizado = () => {
    if (btnnoauto1On === "SI") {
      setbtnnoauto1On("NO");
      setbtnnoauto2On("NO");
    } else {
      setbtnnoauto1On("SI");
    }
  };

  const cerrarLista2 = () => {
    //console.log(listaOn)
    if (paso) {
      setpaso(false);
    }
    if (!paso) {
      setpaso(true);
    }
  };

  const datosADM = () => {
    //console.log(listaOn)
    if (aprobarOn) {
      setaprobarOn(false);
    }
    if (!aprobarOn) {
      setaprobarOn(true);
    }
  };

  const buscarAsigDedu = (e) => {
    let form = new FormData();
    form.append("cedula", data.cedula);
    form.append("tipoNomina", e);
    form.append("boton", "buscarTipoN");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        let data_ = Object.assign({}, data);
        data_["cargo"] = res.data.variable5;
        data_["fechaIngreso"] = res.data.variable6;
        data_["fechaEgreso"] = res.data.variable7;
        data_["tiponomina"] = res.data.variable8;
        data_["tcontrato"] = res.data.variable40;
        data_["salarioBaseMensual"] = res.data.variable10;
        data_["horasDiaria"] = res.data.variable11;
        data_["diasSemanal"] = res.data.variable12;
        data_["salarioHora"] = res.data.variable13;
        data_["salarioDia"] = res.data.variable14;
        data_["salarioQuincenal"] = res.data.variable15;
        data_["sso"] = res.data.variable16;
        data_["ssoDecimo"] = res.data.variable17;
        data_["seguroEducativo"] = res.data.variable18;
        data_["horario"] = res.data.variable35;
        data_["cuentaContable2"] = res.data.variable42;
        setData(data_);
      } // else {
      //   setmensajealerta(true);
      //   settituloM("Menu Empleado.");
      //   setcuerpoM(res.data.variable2);
      // }
      //setpersonal(xpersonal);
    });
  };

  const consultarAdjuntos = () => {
    //console.log(e.target.files)
    setloading(true);
    const form_data = new FormData();

    form_data.append("cedula", data.cedula);
    form_data.append("boton", "Consultar");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/archivo2.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        setloading(false);
        let xrutas = res.data.variable3;
        if (res.data.variable1) {
          setirutas(xrutas);
        } else {
          setmensaje("El Colaborador no tiene Documentos Consignados");
        }
        setpaso(true);
        //console.log(res);
      });
  };

  const botonnoautorizado2 = () => {
    //console.log(this.state.btnemOn)
    if (btnnoauto2On === "SI") {
      setbtnnoauto2On("NO");
    } else {
      setbtnnoauto2On("SI");
    }
  };

  const buscarPersonalC = () => {
    let form = new FormData();
    form.append("boton", "buscarPersonalC");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      //setactivarOn(true);
      let xpersonal = res.data.variable1;
      setpersonal(xpersonal);
      //setpersonal(xpersonal);
    });
  };

  const buscarPersonalI = (e) => {
    let form = new FormData();
    form.append("cedula", e);
    form.append("boton", "buscarPersonalI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setactivarOn(true);
        setlistaOn(false);
        let data_ = Object.assign({}, data);
        data_["cedula"] = res.data.variable2;
        data_["cedulaOt"] = res.data.variable44;
        data_["nombre"] = res.data.variable3;
        data_["profesion"] = res.data.variable4;
        data_["cargo"] = res.data.variable5;
        data_["fechaIngreso"] = res.data.variable6;
        data_["fechaEgreso"] = res.data.variable7;
        data_["tiponominaA"] = res.data.variable8;
        data_["tiponomina"] = res.data.variable8;
        data_["cuentaContable2"] = res.data.variable42;
        data_["status"] = res.data.variable9;
        data_["correo"] = res.data.variable19;
        data_["statusA"] = res.data.variable20;
        data_["statusDe"] = res.data.variable43;

        data_["fechaNaci"] = res.data.variable21;
        data_["edad"] = res.data.variable22;
        data_["direccion"] = res.data.variable23;
        data_["nombreD1"] = res.data.variable24;
        data_["edadD1"] = res.data.variable25;
        data_["parentescoD1"] = res.data.variable26;
        data_["nombreD2"] = res.data.variable27;
        data_["edadD2"] = res.data.variable28;
        data_["parentescoD2"] = res.data.variable29;
        data_["nombreD3"] = res.data.variable30;
        data_["edadD3"] = res.data.variable31;
        data_["parentescoD3"] = res.data.variable32;
        data_["nacionalidad"] = res.data.variable33;

        data_["edocivil"] = res.data.variable34;
        data_["horario"] = res.data.variable35;
        data_["cuenta"] = res.data.variable36;
        data_["banco1"] = res.data.variable37;
        data_["tcuenta"] = res.data.variable38;
        data_["titular"] = res.data.variable39;
        data_["tcontrato"] = res.data.variable40;
        data_["aprobado"] = res.data.variable41;
        data_["telefono"] = res.data.variable45;

        data_["salarioBaseMensual"] = res.data.variable10;
        data_["horasDiaria"] = res.data.variable11;
        data_["diasSemanal"] = res.data.variable12;
        data_["salarioHora"] = res.data.variable13;
        data_["salarioDia"] = res.data.variable14;
        data_["salarioQuincenal"] = res.data.variable15;
        data_["sso"] = res.data.variable16;
        data_["ssoDecimo"] = res.data.variable17;
        data_["seguroEducativo"] = res.data.variable18;
        setData(data_);
      } else {
        setmensajealerta(true);
        settituloM("Menu Empleado.");
        setcuerpoM(res.data.variable2);
      }
      //setpersonal(xpersonal);
    });
  };

  const buscarPersonal = (e) => {
    let form = new FormData();
    form.append("cedula", e);
    form.append("boton", "buscarPersonalI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setactivarOn(true);
        setlistaSuOn(false);
        let data_ = Object.assign({}, data);
        data_["cedula"] = res.data.variable2;
        data_["cedulaOt"] = res.data.variable44;
        data_["nombre"] = res.data.variable3;
        data_["profesion"] = res.data.variable4;
        data_["cargo"] = res.data.variable5;
        data_["fechaIngreso"] = res.data.variable6;
        data_["fechaEgreso"] = res.data.variable7;
        data_["tiponominaA"] = res.data.variable8;
        data_["tiponomina"] = res.data.variable8;
        data_["cuentaContable2"] = res.data.variable42;
        data_["status"] = res.data.variable9;
        data_["correo"] = res.data.variable19;
        data_["statusA"] = res.data.variable20;
        data_["statusDe"] = res.data.variable43;

        data_["fechaNaci"] = res.data.variable21;
        data_["edad"] = res.data.variable22;
        data_["direccion"] = res.data.variable23;
        data_["nombreD1"] = res.data.variable24;
        data_["edadD1"] = res.data.variable25;
        data_["parentescoD1"] = res.data.variable26;
        data_["nombreD2"] = res.data.variable27;
        data_["edadD2"] = res.data.variable28;
        data_["parentescoD2"] = res.data.variable29;
        data_["nombreD3"] = res.data.variable30;
        data_["edadD3"] = res.data.variable31;
        data_["parentescoD3"] = res.data.variable32;
        data_["nacionalidad"] = res.data.variable33;
        data_["edocivil"] = res.data.variable34;
        data_["horario"] = res.data.variable35;
        data_["cuenta"] = res.data.variable36;
        data_["banco1"] = res.data.variable37;
        data_["tcuenta"] = res.data.variable38;
        data_["titular"] = res.data.variable39;
        data_["tcontrato"] = res.data.variable40;
        data_["aprobado"] = res.data.variable41;
        data_["telefono"] = res.data.variable45;

        data_["salarioBaseMensual"] = res.data.variable10;
        data_["horasDiaria"] = res.data.variable11;
        data_["diasSemanal"] = res.data.variable12;
        data_["salarioHora"] = res.data.variable13;
        data_["salarioDia"] = res.data.variable14;
        data_["salarioQuincenal"] = res.data.variable15;
        data_["sso"] = res.data.variable16;
        data_["ssoDecimo"] = res.data.variable17;
        data_["seguroEducativo"] = res.data.variable18;
        setData(data_);
      } else {
        setmensajealerta(true);
        settituloM("Menu Empleado.");
        setcuerpoM(res.data.variable2);
      }
      //setpersonal(xpersonal);
    });
  };

  const guardarEmpleado = (e) => {
    if (data.cedula.length > 0) {
      let form = new FormData();
      form.append("cedula", data.cedula);
      form.append("nombre", data.nombre);
      form.append("profesion", data.profesion);
      form.append("cargo", data.cargo);
      form.append("fechaIngreso", data.fechaIngreso);
      form.append("fechaEgreso", data.fechaEgreso);
      form.append("tiponomina", data.tiponomina);
      form.append("codigoContable", data.cuentaContable2);
      form.append("status", data.status);
      form.append("statusA", data.statusA);
      form.append("correo", data.correo);

      form.append("fechaNaci", data.fechaNaci);
      form.append("edad", data.edad);
      form.append("direccion", data.direccion);
      form.append("nombreD1", data.nombreD1);
      form.append("edadD1", data.edadD1);
      form.append("parentescoD1", data.parentescoD1);
      form.append("nombreD2", data.nombreD2);
      form.append("edadD2", data.edadD2);
      form.append("parentescoD2", data.parentescoD2);
      form.append("nombreD3", data.nombreD3);
      form.append("edadD3", data.edadD3);
      form.append("parentescoD3", data.parentescoD3);
      form.append("nacionalidad", data.nacionalidad);
      form.append("telefono", data.telefono);
      form.append("edocivil", data.edocivil);
      form.append("cuenta", data.cuenta);
      form.append("banco1", data.banco1);
      form.append("tcuenta", data.tcuenta);
      form.append("titular", data.titular);
      form.append("horario", data.horario);
      form.append("tcontrato", data.tcontrato);

      form.append("salarioBaseMensual", data.salarioBaseMensual);
      form.append("horasDiaria", data.horasDiaria);
      form.append("diasSemanal", data.diasSemanal);
      form.append("salarioHora", data.salarioHora);
      form.append("salarioDia", data.salarioDia);
      form.append("salarioQuincenal", data.salarioQuincenal);
      form.append("sso", data.sso);
      form.append("ssoDecimo", data.ssoDecimo);
      form.append("seguroEducativo", data.seguroEducativo);
      form.append("boton", "guardarEmpleado");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setactivarOn(true);
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        }
      });
    }
  };

  const aprobarC = () => {
    let form = new FormData();
    form.append("cedula", data.cedula);
    form.append("boton", "AprobarColaborador");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      //console.log(res.data.variable1);
      //console.log(res.data.variable2);
      if (res.data.variable1) {
        setmensajealerta(true);
        settituloM("Menu Empleado.");
        setcuerpoM(res.data.variable2);
        let data_ = Object.assign({}, data);
        data_["aprobado"] = "SI";
        setData(data_);
      } else {
        setmensajealerta(true);
        settituloM("Menu Empleado.");
        setcuerpoM(res.data.variable2);
      }
    });
  };

  const imprimirContrato = (e) => {
    console.log(data.tiponomina);
    let form = new FormData();
    form.append("nombre", data.nombre);
    form.append("cedula", data.cedula);
    form.append("cedulaOt", data.cedulaOt);
    form.append("edad", data.edad);
    form.append("nacionalidad", data.nacionalidad);
    form.append("edocivil", data.edocivil);
    form.append("direccion", data.direccion);

    form.append("nombreP1", data.nombreD1);
    form.append("nombreP2", data.nombreD2);
    form.append("nombreP3", data.nombreD3);
    form.append("edadP1", data.edadD1);
    form.append("edadP2", data.edadD2);
    form.append("edadP3", data.edadD3);
    form.append("parentescoP1", data.parentescoD1);
    form.append("parentescoP2", data.parentescoD2);
    form.append("parentescoP3", data.parentescoD3);

    form.append("cargo", data.cargo);
    form.append("fechaI", data.fechaIngreso);
    form.append("fechaE", data.fechaEgreso);
    form.append("diasSemanal", data.diasSemanal);
    form.append("horasDiaria", data.horasDiaria);
    form.append("salarioHora", data.salarioHora);
    form.append("horario", data.horario);
    form.append("salarioM", data.salarioBaseMensual);
    if (data.tiponomina === "1" && data.tcontrato === "1") {
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/contratoTiempoDefinidoAD.php",
        responseType: "arraybuffer",
        data: form,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
    if (data.tiponomina === "2" && data.tcontrato === "1") {
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/contratoTiempoIndefinidoAD.php",
        responseType: "arraybuffer",
        data: form,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
    if (data.tiponomina === "1" && data.tcontrato === "2") {
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/contratoTiempoDefinidoDocente.php",
        responseType: "arraybuffer",
        data: form,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
    if (data.tiponomina === "2" && data.tcontrato === "2") {
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/contratoTiempoIndefinidoDocente.php",
        responseType: "arraybuffer",
        data: form,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
    if (data.tiponomina === "3") {
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/contratoHonorarioP.php",
        responseType: "arraybuffer",
        data: form,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
    if (data.tiponomina === "5") {
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/contratoTiempoDefinidoDocente.php",
        responseType: "arraybuffer",
        data: form,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
    if (
      (data.tiponomina === "1" && data.tcontrato === "3") ||
      (data.tiponomina === "2" && data.tcontrato === "3")
    ) {
      setmensajealerta(true);
      settituloM("Menu Empleado.");
      setcuerpoM("Este tipo de opcion No tiene Reporte, verifique");
    }
  };

  const tipoNominaCreada = () => {
    let form = new FormData();
    form.append("cedula", data.cedula);
    form.append("boton", "buscarTipoNomina");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      //console.log(res.data.variable1);
      //console.log(res.data.variable2);
      let tnomip = res.data.variable1;
      setnominaP(tnomip);
      setlistaTnOn(true);
    });
  };

  const activarTN = (e) => {
    let form = new FormData();
    form.append("idtipoN", e);
    form.append("boton", "activarTipoNomina");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      //console.log(res.data.variable1);
      //console.log(res.data.variable2);
      if (res.data.variable1) {
        tipoNominaCreada();
      }
    });
  };

  const modificarEmpleado2 = (e) => {
    if (data.cedula.length > 0 && data.status.length > 0 && data.statusA > 0) {
      let form = new FormData();
      form.append("cedula", data.cedula);
      form.append("nombre", data.nombre);
      form.append("profesion", data.profesion);
      form.append("cargo", data.cargo);
      form.append("fechaIngreso", data.fechaIngreso);
      form.append("fechaEgreso", data.fechaEgreso);
      form.append("tiponominaAnterior", data.tiponominaA);
      form.append("tiponomina", data.tiponomina);
      form.append("codigoContable", data.cuentaContable2);
      form.append("status", data.status);
      form.append("statusA", data.statusA);
      form.append("statusDe", data.statusDe);
      form.append("correo", data.correo);

      form.append("fechaNaci", data.fechaNaci);
      form.append("edad", data.edad);
      form.append("direccion", data.direccion);
      form.append("nombreD1", data.nombreD1);
      form.append("edadD1", data.edadD1);
      form.append("parentescoD1", data.parentescoD1);
      form.append("nombreD2", data.nombreD2);
      form.append("edadD2", data.edadD2);
      form.append("parentescoD2", data.parentescoD2);
      form.append("nombreD3", data.nombreD3);
      form.append("edadD3", data.edadD3);
      form.append("parentescoD3", data.parentescoD3);
      form.append("nacionalidad", data.nacionalidad);
      form.append("telefono", data.telefono);
      form.append("edocivil", data.edocivil);
      form.append("cuenta", data.cuenta);
      form.append("banco1", data.banco1);
      form.append("tcuenta", data.tcuenta);
      form.append("titular", data.titular);
      form.append("horario", data.horario);
      form.append("tcontrato", data.tcontrato);

      form.append("salarioBaseMensual", data.salarioBaseMensual);
      form.append("horasDiaria", data.horasDiaria);
      form.append("diasSemanal", data.diasSemanal);
      form.append("salarioHora", data.salarioHora);
      form.append("salarioDia", data.salarioDia);
      form.append("salarioQuincenal", data.salarioQuincenal);
      form.append("sso", xsso);
      form.append("ssoDecimo", xssoDecimo);
      form.append("seguroEducativo", xseguroEducativo);
      form.append("boton", "modificarEmpleado");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
          let data_ = Object.assign({}, data);
          data_["tiponominaA"] = data.tiponomina;
          setData(data_);
        } else {
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        }
      });
    }
  };

  const modificarEmpleado = (e) => {
    if (data.cedula.length > 0) {
      let form = new FormData();
      form.append("cedula", data.cedula);
      form.append("nombre", data.nombre);
      form.append("profesion", data.profesion);
      form.append("cargo", data.cargo);
      form.append("fechaIngreso", data.fechaIngreso);
      form.append("fechaEgreso", data.fechaEgreso);
      form.append("tiponominaAnterior", data.tiponominaA);
      form.append("tiponomina", data.tiponomina);
      form.append("codigoContable", data.cuentaContable2);
      form.append("status", data.status);
      form.append("statusA", data.statusA);
      form.append("correo", data.correo);

      form.append("fechaNaci", data.fechaNaci);
      form.append("edad", data.edad);
      form.append("direccion", data.direccion);
      form.append("nombreD1", data.nombreD1);
      form.append("edadD1", data.edadD1);
      form.append("parentescoD1", data.parentescoD1);
      form.append("nombreD2", data.nombreD2);
      form.append("edadD2", data.edadD2);
      form.append("parentescoD2", data.parentescoD2);
      form.append("nombreD3", data.nombreD3);
      form.append("edadD3", data.edadD3);
      form.append("parentescoD3", data.parentescoD3);
      form.append("nacionalidad", data.nacionalidad);
      form.append("telefono", data.telefono);
      form.append("edocivil", data.edocivil);
      form.append("cuenta", data.cuenta);
      form.append("banco1", data.banco1);
      form.append("tcuenta", data.tcuenta);
      form.append("titular", data.titular);
      form.append("horario", data.horario);
      form.append("tcontrato", data.tcontrato);

      form.append("salarioBaseMensual", data.salarioBaseMensual);
      form.append("horasDiaria", data.horasDiaria);
      form.append("diasSemanal", data.diasSemanal);
      form.append("salarioHora", data.salarioHora);
      form.append("salarioDia", data.salarioDia);
      form.append("salarioQuincenal", data.salarioQuincenal);
      form.append("sso", xsso);
      form.append("ssoDecimo", xssoDecimo);
      form.append("seguroEducativo", xseguroEducativo);
      form.append("boton", "modificarEmpleado");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
          let data_ = Object.assign({}, data);
          data_["tiponominaA"] = data.tiponomina;
          setData(data_);
        } else {
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        }
      });
    }
  };

  const empleadosSusp = () => {
    let form = new FormData();
    form.append("boton", "buscarSuspendidos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      //console.log(res.data.variable1);
      //console.log(res.data.variable2);
      let tnomipS = res.data.variable1;
      setnominaPS(tnomipS);
      setlistaSuOn(true);
    });
  };

  const guardarHorario = () => {
    if (
      horaE1.length > 0 ||
      horaS1.length > 0 ||
      horaE2.length > 0 ||
      horaS2.length > 0 ||
      horaTS1.length > 0
    ) {
      let form = new FormData();
      // if (horaE1.length === 5 && horaS1.length === 5) {
      form.append("cedulaPersonal", data.cedula);
      form.append("horaEntrada1", horaE1);
      form.append("horaSalida1", horaS1);
      form.append("horaEntrada2", horaE2);
      form.append("horaSalida2", horaS2);
      form.append("horaTope", horaTS1);
      form.append("dia1", lunesCheck);
      form.append("dia2", martesCheck);
      form.append("dia3", miercolesCheck);
      form.append("dia4", juevesCheck);
      form.append("dia5", viernesCheck);
      form.append("dia6", sabadoCheck);
      form.append("fijo", fijoCheck);

      form.append("tipoAsistencia", "1");
      form.append("boton", "crearHorario");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/asistencia.php";
      axios.post(xurl, form, config).then((res) => {
        if (res.data.variable1) {
          horarioNomina();
          setLunesCheck("");
          setMartesCheck("");
          setMiercolesCheck("");
          setJuevesCheck("");
          setViernesCheck("");
          setSabadoCheck("");
          setFijoCheck("");

          setmensajealerta(true);
          settituloM("Menu Horario Empleado.");
          setcuerpoM("El Horario se Cargo Correctamente... ");
        } else {
          setmensajealerta(true);
          settituloM("Menu Horario Empleado.");
          setcuerpoM("Hubo un Error verifique por favor Gracias... ");
        }
      });
    } else {
      setmensajealerta(true);
      settituloM("Menu Horario Empleado.");
      setcuerpoM("Los campos de Horas estan vacios");
    }
  };

  const horarioNomina = () => {
    let form = new FormData();
    form.append("cedula", data.cedula);
    form.append("boton", "buscarHorarioInd");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/asistencia.php";
    axios.post(xurl, form, config).then((res) => {
      // moment(mensaje.variable3).format("hh:MM A")
      if (res.data.variable2 !== "00:00:00") setTiempoE1(res.data.variable2);
      if (res.data.variable3 !== "00:00:00") setTiempoS1(res.data.variable3);
      if (res.data.variable4 !== "00:00:00") setTiempoE2(res.data.variable4);
      if (res.data.variable5 !== "00:00:00") setTiempoS2(res.data.variable5);
      if (res.data.variable6 !== "00:00:00") setTiempoE3(res.data.variable6);
      if (res.data.variable7 !== "00:00:00") setTiempoS3(res.data.variable7);
      if (res.data.variable8 !== "00:00:00") setTiempoE4(res.data.variable8);
      if (res.data.variable9 !== "00:00:00") setTiempoS4(res.data.variable9);
      if (res.data.variable10 !== "00:00:00") setTiempoE5(res.data.variable10);
      if (res.data.variable11 !== "00:00:00") setTiempoS5(res.data.variable11);
      if (res.data.variable12 !== "00:00:00") setTiempoE6(res.data.variable12);
      if (res.data.variable13 !== "00:00:00") setTiempoS6(res.data.variable13);
      if (res.data.variable14 !== "00:00:00") setHoraE2(res.data.variable14);
      if (res.data.variable15 !== "00:00:00") setHoraS2(res.data.variable15);
      if (res.data.variable16 !== "00:00:00") setHoraTS1(res.data.variable16);
      if (res.data.variable17 === "SI") setFijoCheck("SI");

      setActivarHOn(true);
    });
  };

  const eliminarEmpleado = () => {
    if (data.cedula.length > 0) {
      let form = new FormData();
      form.append("cedula", data.cedula);
      form.append("boton", "eliminarEmpleado");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          limpiar();
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        }
      });
    }
  };

  const calcular = () => {
    // let sdia = Number.parseFloat(
    //   data.salarioBaseMensual / (data.diasSemanal * 4)
    // ).toFixed(2);
    let sdia = Number.parseFloat(data.salarioBaseMensual / 26).toFixed(2);

    // let sqince = Number.parseFloat(sdia * (data.diasSemanal * 2)).toFixed(2);
    let sqince = Number.parseFloat(data.salarioBaseMensual / 2).toFixed(2);

    //let shora = Number.parseFloat(sdia / data.horasDiaria).toFixed(2);
    let shora = Number.parseFloat(sdia / data.horasDiaria).toFixed(2);

    let data_ = Object.assign({}, data);
    data_["salarioQuincenal"] = sqince;
    data_["salarioDia"] = sdia;
    data_["salarioHora"] = shora;
    setData(data_);
  };

  const eliarchivo = (e) => {
    //console.log(e);
    setloading(true);

    const form_data = new FormData();
    form_data.append("cedula", data.cedula);
    form_data.append("archivoArriba", e);
    form_data.append("boton", "Eliminar");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/archivo2.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        setloading(false);
        let xrutas = res.data.variable3;
        if (res.data.variable1) {
          setirutas(xrutas);
        } else {
          setirutas(xrutas);
          setmensaje(
            "Es posible que uno o mas archivos no fueron guardados en el servidor"
          );
        }
        //console.log(res);
      });
  };

  const Upload = (e) => {
    //console.log(e.target.files)
    setloading(true);
    const form_data = new FormData();

    let name_fvar = "";
    for (let x = 0; x < e.target.files.length; x++) {
      name_fvar = "file_" + String(x + 1);
      form_data.append(name_fvar, e.target.files[x]);
      //console.log(name_fvar)
    }
    form_data.append("cedula", data.cedula);
    form_data.append("boton", "Guardar");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/archivo2.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        setloading(false);
        if (res.data.variable1) {
          // alert ('Los ArDocumentos fueron enviados exitosamente');
          let xrutas = res.data.variable3;

          setirutas(xrutas);
        } else {
          if (res.data.variable2.length > 0) {
            setpaso(true);
            setmensaje(res.data.variable2);
          } else {
            setpaso(true);
            setmensaje(
              "Es posible que uno o mas archivos no fueron guardados en el servidor"
            );
          }
        }
        //console.log(res);
      });
  };

  const ClickUpload = (e) => {
    document.getElementById("upload-file").click();
  };

  const datosBanco = () => {
    let form = new FormData();
    form.append("anoapagar", anopagar);
    form.append("trimestre", trimestre);
    form.append("mesapagar", mesapagar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteDatosBancarios.php",
      responseType: "arraybuffer",
      data: form,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  const cargarCuentas = () => {
    let form = new FormData();
    form.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/contabilidad.php";
    axios.post(xurl, form, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      setcuentaC(xcuentas);
    });
  };

  const cargarFechas = () => {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        //let xdia = res.data.variable3;
        //dia: xdia,
        setmes(xmes);
        setanio(xanio);
      }
    });
  };

  React.useEffect(() => {
    //console.log(props.cedulaempleado);
    cargarFechas();
    cargarCuentas();
    if (props.cedulaempleado.length > 0) {
      buscarPersonal(props.cedulaempleado);
    }
    //buscarPersonalC();
  }, []);
  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        <div className="titulo-cxc ">
          <p>ADICIONAR EMPLEADOS A LA NOMINA. </p>
        </div>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}
        {loading === true && (
          <div className="cont-spinner row">
            <div className="spinner col-6">
              <RingLoader
                clasName="spinner"
                sizeUnit={"px"}
                size={160}
                width={19}
                radius={20}
                height={160}
                color={"#48e120"}
                loading={loading}
              />
            </div>
          </div>
        )}
        {listaOn && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">Seleccione a un Colaborador</span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    cerrarLista();
                  }}
                >
                  X
                </span>
              </div>
              <div className="cont-listnomi row">
                <div className="listnomi col-12">
                  <table className="tablanomi" border="1">
                    <thead className="theadcreartabla">
                      <tr className="trcreartabla">
                        {/* <th className="xtitunomina1">item.</th> */}
                        <th className="xtitunomina2">Documento.</th>
                        <th className="xtitunomina2">Nombre Colaborador.</th>
                        {/* <th className="xtitunomina1">Monto H/E.</th>
                        <th className="xtitunomina3">S.Quin.</th>
                        <th className="xtitunomina1">Dias N/T.</th>
                        <th className="xtitunomina1">Monto D/N/T.</th>
                        <th className="xtitunomina1">Horas N/T.</th>
                        <th className="xtitunomina1">Monto H/N/T.</th>
                        <th className="xtitunomina1">S.S.O.</th>
                        <th className="xtitunomina1">S.Edu.</th>
                        <th className="xtitunomina1">I.S.L.R.</th>
                        <th className="xtitunomina3">Sub-Total.</th>
                        <th className="xtitunomina3">Monto P.</th>
                        <th className="xtitunomina1">P.Pres.</th>
                        <th className="xtitunomina3">Total a Pagar.</th> */}
                        {/* <th className="xtitunomina1">
                          <span className="cerrarme3"></span>
                        </th> */}
                      </tr>
                    </thead>

                    <tbody className="evocuerpo1">
                      {personal.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              width="20"
                              onClick={() => {
                                buscarPersonalI(item.variable2);
                              }}
                            >
                              {item.variable2}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                buscarPersonalI(item.variable2);
                              }}
                            >
                              {item.variable3}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {paso === true && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-buscar1 row">
                <div
                  className="identificacionr2 col-12"
                  onClick={() => {
                    ClickUpload();
                  }}
                >
                  <p className="subirtexto1">
                    Haz click para adjuntar tus archivos
                  </p>
                  <br />
                  <FeatherIcon icon="upload" size="30px" stroke="#999" />
                  <input
                    onChange={Upload}
                    style={{ display: "none" }}
                    id="upload-file"
                    type="file"
                    multiple
                  />
                </div>
              </div>
              <div className="contenido1 row">
                <div className="form-groupef1 col-12">
                  <div className="preview row">
                    <div className="titulo-notificacion col-12">
                      <p>Archivos ya Guardados en el servidor.</p>
                    </div>
                    {irutas.map((item, i) => {
                      return (
                        <div className="cont-foto">
                          <span
                            className="cerrar"
                            onClick={() => {
                              eliarchivo(item.variable5);
                            }}
                          >
                            X
                          </span>{" "}
                          <a
                            href={
                              "https://institutobilinguelasnaciones.com/terapias/backend/" +
                              item.variable4
                            }
                            target="__blank"
                          >
                            {" "}
                            <img
                              src={
                                "https://institutobilinguelasnaciones.com/terapias/backend/" +
                                item.variable4
                              }
                              key={item.variable5}
                              alt={item.variable5}
                              className="fotos"
                              name={item.variable5}
                            />{" "}
                          </a>
                        </div>
                      );
                    })}
                  </div>

                  <div className="row">
                    <div className="titulo-notificacion col-12">
                      <p>{mensaje}</p>
                    </div>
                    <div className="accionesb col-12">
                      {/* <button className="boton" onClick={cerrarLista3}>
                        Cotejar
                      </button> */}
                      <button className="boton" onClick={cerrarLista2}>
                        Salir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {listaTnOn && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  Nominas Creada a el Colaborador
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    cerrarListaTn();
                  }}
                >
                  X
                </span>
              </div>
              <span className="titulo-nomina"></span>

              <div className="cont-listnomi row">
                <div className="listnomi col-12">
                  <table className="tablanomi" border="1">
                    <thead className="theadcreartabla">
                      <tr className="trcreartabla">
                        {/* <th className="xtitunomina1">item.</th> */}
                        <th className="xtitunomina2x">Tipo de Nomina.</th>
                        <th className="xtitunomina2x">Tipo de Contrato.</th>
                        <th className="xtitunomina1x">Desactivada.</th>
                      </tr>
                    </thead>

                    <tbody className="evocuerpo1">
                      {nominaP.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              className="nombrePersonalx"
                              width="20"
                              onClick={() => {
                                activarTN(item.variable1);
                              }}
                            >
                              {item.variable2}
                            </td>
                            <td
                              className="nombrePersonalx"
                              width="20"
                              onClick={() => {
                                activarTN(item.variable1);
                              }}
                            >
                              {item.variable3}
                            </td>

                            <td
                              className="nombrePersonalx"
                              width="20"
                              onClick={() => {
                                activarTN(item.variable1);
                              }}
                            >
                              {item.variable4}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {activarHOn && (
          <div className="cont-tablanominaHx row">
            <div className="tablanomina1 col-8">
              <div className="cont-titulomedi col-12">
                <span className="titulocasosHx">
                  Horario de Trabajo Especialistas.
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    activarH();
                  }}
                >
                  X
                </span>
              </div>

              <div className="cont-listnomiHx row">
                <div className="cont-opcioncas col-1">
                  <p className="tituloofertaH">LUNES.</p>
                  <input
                    className="horariochk"
                    type="checkbox"
                    name="lunesCheck"
                    id="lunesCheck"
                    value="SI"
                    checked={lunesCheck === "SI"}
                    onChange={onChange1}
                  />
                </div>

                <div className="cont-opcioncas col-1">
                  <p className="tituloofertaH">MARTES.</p>
                  <input
                    className="horariochk"
                    type="checkbox"
                    name="martesCheck"
                    id="martesCheck"
                    value="SI"
                    checked={martesCheck === "SI"}
                    onChange={onChange1}
                  />
                </div>
                <div className="cont-opcioncas col-1">
                  <p className="tituloofertaH">MIERCOLES.</p>
                  <input
                    className="horariochk"
                    type="checkbox"
                    name="miercolesCheck"
                    id="miercolesCheck"
                    value="SI"
                    checked={miercolesCheck === "SI"}
                    onChange={onChange1}
                  />
                </div>
                <div className="cont-opcioncas col-1">
                  <p className="tituloofertaH">JUEVES.</p>
                  <input
                    className="horariochk"
                    type="checkbox"
                    name="juevesCheck"
                    id="juevesCheck"
                    value="SI"
                    checked={juevesCheck === "SI"}
                    onChange={onChange1}
                  />
                </div>
                <div className="cont-opcioncas col-1">
                  <p className="tituloofertaH">VIERNES.</p>
                  <input
                    className="horariochk"
                    type="checkbox"
                    name="viernesCheck"
                    id="viernesCheck"
                    value="SI"
                    checked={viernesCheck === "SI"}
                    onChange={onChange1}
                  />
                </div>
                <div className="cont-opcioncas col-1">
                  <p className="tituloofertaH">SABADO.</p>
                  <input
                    className="horariochk"
                    type="checkbox"
                    name="sabadoCheck"
                    id="sabadoCheck"
                    value="SI"
                    checked={sabadoCheck === "SI"}
                    onChange={onChange1}
                  />
                </div>
              </div>

              <div className="cont-listnomiHx row">
                <div className="cont-personal col-3">
                  <label className="titulo-salario2x">
                    Hora de Entrada.
                    {/* <input
                      className="container"
                      type="time"
                      name="horaE1"
                      id="horaE1"
                      autoComplete="off"
                      onChange={onChange3}
                      value={typeof horaE1 !== "undefined" ? horaE1 : ""}
                    /> */}
                    <select
                      id="horaE1"
                      name="horaE1"
                      value={typeof horaE1 !== "undefined" ? horaE1 : ""}
                      onChange={onChange3}
                    >
                      <option value="">Hora de Entrada.</option>
                      <option value="07:00:00">07:00 am.</option>
                      <option value="08:00:00">08:00 am.</option>
                      <option value="09:00:00">09:00 am.</option>
                      <option value="10:00:00">10:00 am.</option>
                      <option value="11:00:00">11:00 am.</option>
                      <option value="12:00:00">12:00 am.</option>
                      <option value="13:00:00">01:00 pm.</option>
                      <option value="14:00:00">02:00 pm.</option>
                      <option value="15:00:00">03:00 pm.</option>
                      <option value="16:00:00">04:00 pm.</option>
                      <option value="17:00:00">05:00 pm.</option>
                      <option value="18:00:00">06:00 pm.</option>
                      <option value="19:00:00">07:00 pm.</option>
                    </select>
                  </label>
                </div>
                <div className="cont-personal col-3">
                  <label className="titulo-salario2x">
                    Hora de Salida.
                    {/* <input
                      className="container"
                      type="time"
                      name="horaS1"
                      id="horaS1"
                      autoComplete="off"
                      // placeholder="Hora de Salida 07:00 pm"
                      // maxLength="5"
                      onChange={onChange3}
                      value={typeof horaS1 !== "undefined" ? horaS1 : ""}
                    /> */}
                    <select
                      id="horaS1"
                      name="horaS1"
                      value={typeof horaS1 !== "undefined" ? horaS1 : ""}
                      onChange={onChange3}
                    >
                      <option value="">Hora de Salida.</option>
                      <option value="07:00:00">07:00 am.</option>
                      <option value="08:00:00">08:00 am.</option>
                      <option value="09:00:00">09:00 am.</option>
                      <option value="10:00:00">10:00 am.</option>
                      <option value="11:00:00">11:00 am.</option>
                      <option value="12:00:00">12:00 am.</option>
                      <option value="13:00:00">01:00 pm.</option>
                      <option value="14:00:00">02:00 pm.</option>
                      <option value="15:00:00">03:00 pm.</option>
                      <option value="16:00:00">04:00 pm.</option>
                      <option value="17:00:00">05:00 pm.</option>
                      <option value="18:00:00">06:00 pm.</option>
                      <option value="19:00:00">07:00 pm.</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className="cont-listnomiHx row">
                <div className="cont-opcioncas col-2">
                  <p className="tituloofertaH">Personal Fijo.</p>
                  <input
                    className="horariochk"
                    type="checkbox"
                    name="fijoCheck"
                    id="fijoCheck"
                    value="SI"
                    checked={fijoCheck === "SI"}
                    onChange={onChange1}
                  />
                </div>
                <div className="cont-personal col-3">
                  <label className="titulo-salario2x">
                    Hora Tope Salida.{" "}
                    <select
                      id="horaTS1"
                      name="horaTS1"
                      value={typeof horaTS1 !== "undefined" ? horaTS1 : ""}
                      onChange={onChange3}
                    >
                      <option value="">Hora Tope Salida.</option>
                      <option value="13:00:00">01:00 pm.</option>
                      <option value="13:30:00">01:30 pm.</option>
                      <option value="14:00:00">02:00 pm.</option>
                      <option value="14:30:00">02:30 pm.</option>
                      <option value="15:00:00">03:00 pm.</option>
                      <option value="15:30:00">03:30 pm.</option>
                      <option value="16:00:00">04:00 pm.</option>
                      <option value="16:30:00">04:30 pm.</option>
                      <option value="17:00:00">05:00 pm.</option>
                      <option value="17:30:00">05:30 pm.</option>
                      <option value="18:00:00">06:00 pm.</option>
                      <option value="18:30:00">06:30 pm.</option>
                      <option value="19:00:00">07:00 pm.</option>
                      <option value="19:30:00">07:30 pm.</option>
                    </select>
                  </label>
                </div>
              </div>

              <div className="cont-listnomiHx row">
                <div className="cont-titulomedix col-12">
                  <span className="titulocasosx">Horario Libre</span>
                </div>
                <div className="cont-personal col-3">
                  <label className="titulo-salario2x">
                    Hora Inicio Libre.
                    <input
                      className="container"
                      type="time"
                      name="horaE2"
                      id="horaE2"
                      autoComplete="off"
                      placeholder="Hora Inicio Libre 07:00 am"
                      maxLength="5"
                      onChange={onChange3}
                      value={typeof horaE2 !== "undefined" ? horaE2 : ""}
                    />
                  </label>
                </div>
                <div className="cont-personal col-3">
                  <label className="titulo-salario2x">
                    Hora Final Libre.
                    <input
                      className="container"
                      type="time"
                      name="horaS2"
                      id="horaS2"
                      autoComplete="off"
                      placeholder="Hora Final Libre 07:00 pm"
                      maxLength="5"
                      onChange={onChange3}
                      value={typeof horaS2 !== "undefined" ? horaS2 : ""}
                    />
                  </label>
                </div>
                {/* </div> */}
                <div className="cont-personal col-12">
                  <button className="botonN" onClick={guardarHorario}>
                    Guardar Horario
                  </button>
                  <button className="boton" onClick={activarH}>
                    Salir
                  </button>
                </div>
              </div>
              <div className="row">
                {/* <div className=" col-12"> */}
                <div className="cont-dias col-2">
                  <span className="titulocasosx">Lunes</span>
                </div>
                <div className="cont-dias col-2">
                  <span className="titulocasosx">Martes</span>
                </div>
                <div className="cont-dias col-2">
                  <span className="titulocasosx">Miercoles</span>
                </div>
                <div className="cont-dias col-2">
                  <span className="titulocasosx">Jueves</span>
                </div>
                <div className="cont-dias col-2">
                  <span className="titulocasosx">Viernes</span>
                </div>
                <div className="cont-dias col-2">
                  <span className="titulocasosx">Sabado</span>
                </div>
                <div className=" col-1">H/Entrada</div>
                <div className=" col-1">H/Salida</div>
                <div className=" col-1">H/Entrada</div>
                <div className=" col-1">H/Salida</div>
                <div className=" col-1">H/Entrada</div>
                <div className=" col-1">H/Salida</div>
                <div className=" col-1">H/Entrada</div>
                <div className=" col-1">H/Salida</div>
                <div className=" col-1">H/Entrada</div>
                <div className=" col-1">H/Salida</div>
                <div className=" col-1">H/Entrada</div>
                <div className=" col-1">H/Salida</div>
                <div className="titulocasosx col-1">{tiempoE1}</div>
                <div className="titulocasosx col-1">{tiempoS1}</div>
                <div className="titulocasosx col-1">{tiempoE2}</div>
                <div className="titulocasosx col-1">{tiempoS2}</div>
                <div className="titulocasosx col-1">{tiempoE3}</div>
                <div className="titulocasosx col-1">{tiempoS3}</div>
                <div className="titulocasosx col-1">{tiempoE4}</div>
                <div className="titulocasosx col-1">{tiempoS4}</div>
                <div className="titulocasosx col-1">{tiempoE5}</div>
                <div className="titulocasosx col-1">{tiempoS5}</div>
                <div className="titulocasosx col-1">{tiempoE6}</div>
                <div className="titulocasosx col-1">{tiempoS6}</div>
              </div>
            </div>
            {/* </div> */}
          </div>
        )}

        {listaDatosBOn && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-listmedi row">
                <div className="cont-personal col-3">
                  <label className="titulo-salario2">
                    Quincena a consultar.
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={typeof trimestre !== "undefined" ? trimestre : ""}
                      onChange={onChange}
                    >
                      <option value="0">Seleccione Quincena</option>
                      <option value="1">Primera Quincena.</option>
                      <option value="2">Segunda Quincena.</option>
                    </select>
                  </label>
                </div>
                <div className="cont-personal col-2">
                  <label className="titulo-salario2">
                    Mes a Pagar.
                    <select
                      id="mesapagar"
                      name="mesapagar"
                      value={typeof mesapagar !== "undefined" ? mesapagar : ""}
                      onChange={onChange}
                    >
                      <option value="0">Mes a Pagar</option>
                      {mes.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
                <div className="cont-personal col-2">
                  <label className="titulo-salario2">
                    Año a Pagar.
                    <select
                      id="anopagar"
                      name="anopagar"
                      value={typeof anopagar !== "undefined" ? anopagar : ""}
                      onChange={onChange}
                    >
                      <option value="0">Año a Pagar</option>
                      {anio.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              </div>

              <div className="cont-personal col-12">
                <button className="botonN" onClick={datosBanco}>
                  Generar Reporte
                </button>
                <button className="boton" onClick={cerrarListaDatosB}>
                  Salir
                </button>
              </div>
            </div>
          </div>
        )}

        {listaSuOn && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  Empleados Suspendidos o Retirados
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    cerrarListaSu();
                  }}
                >
                  X
                </span>
              </div>
              <span className="titulo-nomina"></span>

              <div className="cont-listnomi row">
                <div className="listnomi col-12">
                  <table className="tablanomi" border="1">
                    <thead className="theadcreartabla">
                      <tr className="trcreartabla">
                        <th className="xtitunomina2x">Documento.</th>
                        <th className="xtitunomina2x">Trabajador.</th>
                        <th className="xtitunomina1x">Estatus.</th>
                      </tr>
                    </thead>

                    <tbody className="evocuerpo1">
                      {nominaPS.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              className="nombrePersonalx"
                              width="20"
                              onClick={() => {
                                buscarPersonal(item.variable2);
                              }}
                            >
                              {item.variable2}
                            </td>
                            <td
                              className="nombrePersonalx"
                              width="20"
                              onClick={() => {
                                buscarPersonal(item.variable2);
                              }}
                            >
                              {item.variable3}
                            </td>
                            <td
                              className="nombrePersonalx"
                              width="20"
                              onClick={() => {
                                buscarPersonal(item.variable2);
                              }}
                            >
                              {item.variable4}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {!aprobarOn && (
          <div>
            <div className="cont-listmedi row">
              <div className="cont-personal col-2">
                <label className="titulo-salario2">
                  Cedula Empleado.
                  <input
                    className="form-control"
                    type="text"
                    name="cedula"
                    id="cedula"
                    readOnly={!activarOn ? "" : "readonly"}
                    autoComplete="off"
                    placeholder="Cedula Empleado"
                    maxLength="15"
                    onChange={onChange}
                    value={
                      typeof data.cedula !== "undefined"
                        ? data.cedula.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-3">
                <label className="titulo-salario2">
                  Nombre Empleado.
                  <input
                    className="form-control"
                    type="text"
                    name="nombre"
                    id="nombre"
                    autoComplete="off"
                    placeholder="Nombre del Empleado"
                    maxLength="200"
                    onChange={onChange}
                    value={
                      typeof data.nombre !== "undefined"
                        ? data.nombre.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-3">
                <label className="titulo-salario2">
                  Fecha Naci.
                  <input
                    className="form-control"
                    type="date"
                    name="fechaNaci"
                    id="fechaNaci"
                    autoComplete="off"
                    maxLength="10"
                    onChange={onChange}
                    value={
                      typeof data.fechaNaci !== "undefined"
                        ? data.fechaNaci
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-1">
                <label className="titulo-salario2">
                  Edad.
                  <input
                    className="form-control"
                    type="text"
                    name="edad"
                    id="edad"
                    autoComplete="off"
                    placeholder="Edad"
                    maxLength="3"
                    onChange={onChange}
                    value={typeof data.edad !== "undefined" ? data.edad : ""}
                  />
                </label>
              </div>
              <div className="cont-personal col-2">
                <label className="titulo-salario2">
                  Correo Emp.
                  <input
                    className="form-control"
                    type="text"
                    name="correo"
                    id="correo"
                    autoComplete="off"
                    placeholder="Correo Emp."
                    maxLength="50"
                    onChange={onChange}
                    value={
                      typeof data.correo !== "undefined"
                        ? data.correo.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personal col-3">
                <input
                  className="form-control"
                  type="text"
                  name="direccion"
                  id="direccion"
                  autoComplete="off"
                  placeholder="Dirección."
                  maxLength="50"
                  onChange={onChange}
                  value={
                    typeof data.direccion !== "undefined"
                      ? data.direccion.toUpperCase()
                      : ""
                  }
                />
              </div>

              <div className="cont-personal col-3">
                <label className="titulo-salario2">
                  {/* Nombre Dependiente 1. */}
                  <input
                    className="form-control"
                    type="text"
                    name="nombreD1"
                    id="nombreD1"
                    autoComplete="off"
                    placeholder="Nombre Dependiente 1."
                    maxLength="100"
                    onChange={onChange}
                    value={
                      typeof data.nombreD1 !== "undefined"
                        ? data.nombreD1.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-1">
                <label className="titulo-salario2">
                  {/* Edad Dependiente. */}
                  <input
                    className="form-control"
                    type="text"
                    name="edadD1"
                    id="edadD1"
                    autoComplete="off"
                    placeholder="Edad."
                    maxLength="3"
                    onChange={onChange}
                    value={
                      typeof data.edadD1 !== "undefined"
                        ? data.edadD1.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-3">
                <label className="titulo-salario2">
                  {/* Parentesco. */}
                  <input
                    className="form-control"
                    type="text"
                    name="parentescoD1"
                    id="parentescoD1"
                    autoComplete="off"
                    placeholder="Parentesco."
                    maxLength="25"
                    onChange={onChange}
                    value={
                      typeof data.parentescoD1 !== "undefined"
                        ? data.parentescoD1.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="accionesb col-1">
                {btnnoauto1On === "SI" ? (
                  <button className="botonem" onClick={botonnoautorizado}>
                    -
                  </button>
                ) : (
                  <button className="botonem" onClick={botonnoautorizado}>
                    +
                  </button>
                )}
              </div>
            </div>
            {btnnoauto1On === "SI" && (
              <div className="cont-listmedi row">
                <div className="cont-personal col-3">
                  <label className="titulo-salario2">
                    {/* Nombre Dependiente 2. */}
                    <input
                      className="form-control"
                      type="text"
                      name="nombreD2"
                      id="nombreD2"
                      autoComplete="off"
                      placeholder="Nombre Dependiente 2."
                      maxLength="100"
                      onChange={onChange}
                      value={
                        typeof data.nombreD2 !== "undefined"
                          ? data.nombreD2.toUpperCase()
                          : ""
                      }
                    />
                  </label>
                </div>
                <div className="cont-personal col-1">
                  <label className="titulo-salario2">
                    {/* Edad Dependiente. */}
                    <input
                      className="form-control"
                      type="text"
                      name="edadD2"
                      id="edadD2"
                      autoComplete="off"
                      placeholder="Edad."
                      maxLength="3"
                      onChange={onChange}
                      value={
                        typeof data.edadD2 !== "undefined"
                          ? data.edadD2.toUpperCase()
                          : ""
                      }
                    />
                  </label>
                </div>
                <div className="cont-personal col-3">
                  <label className="titulo-salario2">
                    {/* Parentesco. */}
                    <input
                      className="form-control"
                      type="text"
                      name="parentescoD2"
                      id="parentescoD2"
                      autoComplete="off"
                      placeholder="Parentesco."
                      maxLength="25"
                      onChange={onChange}
                      value={
                        typeof data.parentescoD2 !== "undefined"
                          ? data.parentescoD2.toUpperCase()
                          : ""
                      }
                    />
                  </label>
                </div>
                <div className="accionesb col-1">
                  {btnnoauto2On === "SI" ? (
                    <button className="botonem" onClick={botonnoautorizado2}>
                      -
                    </button>
                  ) : (
                    <button className="botonem" onClick={botonnoautorizado2}>
                      +
                    </button>
                  )}
                </div>
              </div>
            )}
            {btnnoauto2On === "SI" && (
              <div className="cont-listmedi row">
                <div className="cont-personal col-3">
                  <label className="titulo-salario2">
                    {/* Nombre Dependiente 3. */}
                    <input
                      className="form-control"
                      type="text"
                      name="nombreD3"
                      id="nombreD3"
                      autoComplete="off"
                      placeholder="Nombre Dependiente 3."
                      maxLength="100"
                      onChange={onChange}
                      value={
                        typeof data.nombreD3 !== "undefined"
                          ? data.nombreD3.toUpperCase()
                          : ""
                      }
                    />
                  </label>
                </div>
                <div className="cont-personal col-1">
                  <label className="titulo-salario2">
                    {/* Edad Dependiente. */}
                    <input
                      className="form-control"
                      type="text"
                      name="edadD3"
                      id="edadD3"
                      autoComplete="off"
                      placeholder="Edad."
                      maxLength="3"
                      onChange={onChange}
                      value={
                        typeof data.edadD3 !== "undefined"
                          ? data.edadD3.toUpperCase()
                          : ""
                      }
                    />
                  </label>
                </div>
                <div className="cont-personal col-3">
                  <label className="titulo-salario2">
                    {/* Parentesco. */}
                    <input
                      className="form-control"
                      type="text"
                      name="parentescoD3"
                      id="parentescoD3"
                      autoComplete="off"
                      placeholder="Parentesco."
                      maxLength="30"
                      onChange={onChange}
                      value={
                        typeof data.parentescoD3 !== "undefined"
                          ? data.parentescoD3.toUpperCase()
                          : ""
                      }
                    />
                  </label>
                </div>
              </div>
            )}
            <div className="cont-listmedi row">
              <div className="cont-personal col-4">
                <label className="titulo-salario2">
                  Profesion Empleado.
                  <input
                    className="form-control"
                    type="text"
                    name="profesion"
                    id="profesion"
                    autoComplete="off"
                    placeholder="Profesion del Empleado"
                    maxLength="25"
                    onChange={onChange}
                    value={
                      typeof data.profesion !== "undefined"
                        ? data.profesion.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-3">
                <label className="titulo-salario2">
                  Nacionalidad.
                  <input
                    className="form-control"
                    type="text"
                    name="nacionalidad"
                    id="nacionalidad"
                    autoComplete="off"
                    placeholder="Nacionalidad."
                    maxLength="30"
                    onChange={onChange}
                    value={
                      typeof data.nacionalidad !== "undefined"
                        ? data.nacionalidad.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-2">
                <label className="titulo-salario2">
                  Estado Civil.
                  <select
                    id="edocivil"
                    name="edocivil"
                    value={data.edocivil}
                    onChange={onChange}
                  >
                    <option value="0">-- Estado Civil --</option>
                    <option value="1">Soltero(a).</option>
                    <option value="2">Casado(a).</option>
                    <option value="3">Unido(a).</option>
                    <option value="4">Viudo(a).</option>
                  </select>
                </label>
              </div>
              <div className="cont-personal col-3">
                <label className="titulo-salario2">
                  Telefono.
                  <input
                    className="form-control"
                    type="text"
                    name="telefono"
                    id="telefono"
                    autoComplete="off"
                    placeholder="Telefono."
                    maxLength="130"
                    onChange={onChange}
                    value={
                      typeof data.telefono !== "undefined"
                        ? data.telefono.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personal col-2">
                <label className="titulo-salario">
                  Número de Cuenta
                  <input
                    className="form-control"
                    type="text"
                    name="cuenta"
                    id="cuenta"
                    autoComplete="off"
                    placeholder="Cuenta del Banco"
                    maxLength="30"
                    onChange={onChange}
                    value={
                      typeof data.cuenta !== "undefined"
                        ? data.cuenta.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-2">
                <label className="titulo-salario">
                  Nombre del Banco
                  <input
                    className="form-control"
                    type="text"
                    name="banco1"
                    id="banco1"
                    autoComplete="off"
                    placeholder="Nombre del Banco"
                    maxLength="30"
                    onChange={onChange}
                    value={
                      typeof data.banco1 !== "undefined"
                        ? data.banco1.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-2">
                <label className="titulo-salario">
                  Tipo de Cuenta
                  <input
                    className="form-control"
                    type="text"
                    name="tcuenta"
                    id="tcuenta"
                    autoComplete="off"
                    placeholder="Tipo de Cuenta"
                    maxLength="30"
                    onChange={onChange}
                    value={
                      typeof data.tcuenta !== "undefined"
                        ? data.tcuenta.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
              <div className="cont-personal col-2">
                <label className="titulo-salario">
                  Titular Cuenta
                  <input
                    className="form-control"
                    type="text"
                    name="titular"
                    id="titular"
                    autoComplete="off"
                    placeholder="Titular Cuenta"
                    maxLength="30"
                    onChange={onChange}
                    value={
                      typeof data.titular !== "undefined"
                        ? data.titular.toUpperCase()
                        : ""
                    }
                  />
                </label>
              </div>
            </div>
          </div>
        )}
        {aprobarOn && (
          <div className="cont-listmedi row">
            <div className="cont-personal col-3">
              <label className="titulo-salario">
                Cargo Empleado.
                <input
                  className="form-control"
                  type="text"
                  name="cargo"
                  id="cargo"
                  autoComplete="off"
                  placeholder="Cargo del Empleado"
                  maxLength="25"
                  onChange={onChange}
                  value={
                    typeof data.cargo !== "undefined"
                      ? data.cargo.toUpperCase()
                      : ""
                  }
                />
              </label>
            </div>
            <div className="cont-personal col-2">
              <label className="titulo-salario">
                Fecha Ingreso.
                <input
                  className="form-control"
                  type="date"
                  name="fechaIngreso"
                  id="fechaIngreso"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={
                    typeof data.fechaIngreso !== "undefined"
                      ? data.fechaIngreso
                      : ""
                  }
                />
              </label>
            </div>
            <div className="cont-personal col-2">
              <label className="titulo-salario">
                Fecha Egreso.
                <input
                  className="form-control"
                  type="date"
                  name="fechaEgreso"
                  id="fechaEgreso"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={
                    typeof data.fechaEgreso !== "undefined"
                      ? data.fechaEgreso
                      : ""
                  }
                />
              </label>
            </div>
            <div className="cont-personal col-3">
              <label className="titulo-salario">
                Tipo Nomina.
                <select
                  id="tiponomina"
                  name="tiponomina"
                  value={data.tiponomina}
                  onChange={onChange2}
                >
                  <option value="0">-- Tipo de Nomina --</option>
                  {/* <option value="1">
                    Colaboradores con Deducciones Definido.
                  </option>
                  <option value="2">
                    Colaboradores con Deducciones Indefinido.
                  </option> */}
                  <option value="3">Honorario Profesionales.</option>
                  <option value="5">Contrato Por Hora Definido.</option>
                </select>
              </label>
            </div>
            <div className="cont-personal col-2">
              <label className="titulo-salario2">
                Tipo de Contrato.
                <select
                  id="tcontrato"
                  name="tcontrato"
                  value={data.tcontrato}
                  onChange={onChange}
                >
                  <option value="0">-- Tipo Contrato --</option>
                  <option value="1">Administrativo.</option>
                  {/* <option value="2">Docente.</option> */}
                  <option value="3">Honorario Profesionales.</option>
                  <option value="5">Contrato Por Hora Definido.</option>
                </select>
              </label>
            </div>
          </div>
        )}
        {aprobarOn && (
          <div className="cont-listmedi row">
            <div className="contenedorcedulaC col-6">
              <label className="titulo-salario2">
                Codigo Contable.
                <select
                  id="cuentaContable2"
                  name="cuentaContable2"
                  value={data.cuentaContable2}
                  onChange={onChange}
                >
                  <option>Cuenta Contable</option>

                  {cuentaC.map((item, i) => {
                    return (
                      <option value={item.variable2}>
                        {item.variable6 + " - " + item.variable3}{" "}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div className="cont-personal col-2">
              <label className="titulo-salario2">
                Horario.
                <input
                  className="form-control"
                  type="text"
                  name="horario"
                  id="horario"
                  autoComplete="off"
                  placeholder="Horario."
                  maxLength="50"
                  onChange={onChange}
                  value={
                    typeof data.horario !== "undefined"
                      ? data.horario.toUpperCase()
                      : ""
                  }
                />
              </label>
            </div>
            <div className="cont-personal col-2">
              <label className="titulo-salario2">
                Status Acade.
                <select
                  id="status"
                  name="status"
                  value={data.status}
                  onChange={onChange}
                >
                  <option value="0">-Status Acade.-</option>
                  <option value="1">Activo.</option>
                  <option value="2">Suspendido.</option>
                  <option value="3">Retirado.</option>
                </select>
              </label>
            </div>
            <div className="cont-personal col-2">
              <label className="titulo-salario2">
                Status Adm.
                <select
                  id="statusA"
                  name="statusA"
                  value={data.statusA}
                  onChange={onChange}
                >
                  <option value="0">-Status Adm.-</option>
                  <option value="1">Activo.</option>
                  <option value="2">Suspendido.</option>
                  <option value="3">Retirado.</option>
                </select>
              </label>
            </div>
            {data.tiponomina === "3" && (
              <div className="cont-personal col-1">
                <label className="titulo-salario2">
                  Activar Decimo.
                  <select
                    id="statusDe"
                    name="statusDe"
                    value={data.statusDe}
                    onChange={onChange}
                  >
                    <option>-Status Decimo-</option>
                    <option value="1">Activo</option>
                    <option value="2">No Activo</option>
                  </select>
                </label>
              </div>
            )}
            <div className="cont-listmedi row">
              <div className="titulo-cxc1 col-5 ">
                <p className="confi">CONFIGURAR ASIGNACIONES. </p>
              </div>
              <div className="col-1">
                <p></p>
              </div>
              <div className="titulo-cxc1 col-5 ">
                <p className="confi">CONFIGURAR DEDUCCIONES. </p>
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personal col-6">
                <div className="cont-listmedi row">
                  <div className="cont-personal col-5">
                    <label className="titulo-salario">
                      Salario Base.
                      <input
                        className="form-control"
                        type="text"
                        name="salarioBaseMensual"
                        id="salarioBaseMensual"
                        autoComplete="off"
                        placeholder="Salario Base"
                        maxLength="10"
                        onChange={onChange}
                        value={
                          typeof data.salarioBaseMensual !== "undefined"
                            ? data.salarioBaseMensual
                            : ""
                        }
                      />
                    </label>
                  </div>
                  <div className="cont-personal col-5">
                    <label className="titulo-salario">
                      Horas Diarias.
                      <input
                        className="form-control"
                        type="text"
                        name="horasDiaria"
                        id="horasDiaria"
                        autoComplete="off"
                        placeholder="Horas Diarias"
                        maxLength="5"
                        onChange={onChange}
                        value={
                          typeof data.horasDiaria !== "undefined"
                            ? data.horasDiaria
                            : ""
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="cont-listmedi row">
                  <div className="cont-personal col-5">
                    <label className="titulo-salario">
                      Dias Semanal.
                      <input
                        className="form-control"
                        type="text"
                        name="diasSemanal"
                        id="diasSemanal"
                        autoComplete="off"
                        placeholder="Dias Semanal"
                        maxLength="5"
                        onChange={onChange}
                        value={
                          typeof data.diasSemanal !== "undefined"
                            ? data.diasSemanal
                            : ""
                        }
                      />
                    </label>
                  </div>
                  <div className="cont-personal col-5">
                    <button className="botonN" onClick={calcular}>
                      Calcular Salario
                    </button>
                  </div>
                </div>

                <div className="cont-listmedi row">
                  <div className="cont-personal col-3">
                    <label className="titulo-salario">
                      Salario/hora.
                      <input
                        className="form-control"
                        type="text"
                        name="salarioHora"
                        id="salarioHora"
                        readOnly="readonly"
                        autoComplete="off"
                        maxLength="10"
                        onChange={onChange}
                        value={
                          typeof data.salarioHora !== "undefined"
                            ? data.salarioHora
                            : ""
                        }
                      />
                    </label>
                  </div>
                  <div className="cont-personal col-3">
                    <label className="titulo-salario">
                      Salario/Dia.
                      <input
                        className="form-control"
                        type="text"
                        name="salarioDia"
                        id="salarioDia"
                        readOnly="readonly"
                        autoComplete="off"
                        maxLength="10"
                        onChange={onChange}
                        value={
                          typeof data.salarioDia !== "undefined"
                            ? data.salarioDia
                            : ""
                        }
                      />
                    </label>
                  </div>
                  <div className="cont-personal col-3">
                    <label className="titulo-salario">
                      Quincena.
                      <input
                        className="form-control"
                        type="text"
                        name="salarioQuincenal"
                        id="salarioQuincenal"
                        readOnly="readonly"
                        autoComplete="off"
                        maxLength="10"
                        onChange={onChange}
                        value={
                          typeof data.salarioQuincenal !== "undefined"
                            ? data.salarioQuincenal
                            : ""
                        }
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="cont-personal col-6">
                <div className="cont-listmedi row">
                  <div className="cont-personal col-5">
                    <label className="titulo-salario">
                      S. Social.
                      <input
                        className="form-control"
                        type="text"
                        name="sso"
                        id="sso"
                        readOnly={
                          data.tiponomina === "1" ||
                          data.tiponomina === "2" ||
                          data.tiponomina === "5"
                            ? ""
                            : "readonly"
                        }
                        autoComplete="off"
                        placeholder="S.Social"
                        maxLength="10"
                        onChange={onChange}
                        value={
                          typeof data.sso !== "undefined" || data.sso === "0"
                            ? data.tiponomina === "1" ||
                              data.tiponomina === "2" ||
                              data.tiponomina === "5"
                              ? xsso
                              : ""
                            : ""
                        }
                      />
                    </label>
                  </div>
                  <div className="cont-personal col-5">
                    <label className="titulo-salario">
                      SSO XIII MES.
                      <input
                        className="form-control"
                        type="text"
                        name="ssoDecimo"
                        id="ssoDecimo"
                        readOnly={
                          data.tiponomina === "1" ||
                          data.tiponomina === "2" ||
                          data.tiponomina === "5"
                            ? ""
                            : "readonly"
                        }
                        autoComplete="off"
                        placeholder="SSO XIII MES"
                        maxLength="10"
                        onChange={onChange}
                        value={
                          typeof data.ssoDecimo !== "undefined" ||
                          data.ssoDecimo === "0"
                            ? data.tiponomina === "1" ||
                              data.tiponomina === "2" ||
                              data.tiponomina === "5"
                              ? xssoDecimo
                              : ""
                            : ""
                        }
                      />
                    </label>
                  </div>
                </div>
                <div className="cont-listmedi row">
                  <div className="cont-personal col-5">
                    <label className="titulo-salario">
                      Seguro Educativo.
                      <input
                        className="form-control"
                        type="text"
                        name="seguroEducativo"
                        id="seguroEducativo"
                        readOnly={
                          data.tiponomina === "1" ||
                          data.tiponomina === "2" ||
                          data.tiponomina === "5"
                            ? ""
                            : "readonly"
                        }
                        autoComplete="off"
                        placeholder="Seguro Educ."
                        maxLength="10"
                        onChange={onChange}
                        value={
                          typeof data.seguroEducativo !== "undefined" ||
                          data.seguroEducativo === "0"
                            ? data.tiponomina === "1" ||
                              data.tiponomina === "2" ||
                              data.tiponomina === "5"
                              ? xseguroEducativo
                              : ""
                            : ""
                        }
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!aprobarOn && (
          <div className="contenedorcedula1 col-12">
            <button
              className={!activarOn ? "boton" : "botoncp"}
              onClick={!activarOn ? guardarEmpleado : ""}
            >
              Guardar Empleado
            </button>
            <button
              className={activarOn ? "boton" : "botoncp"}
              onClick={activarOn ? modificarEmpleado : ""}
            >
              Modificar Empleado
            </button>
            {props.usuario1.nivel !== "2" && (
              <button
                className={activarOn ? "boton" : "botoncp"}
                onClick={activarOn ? eliminarEmpleado : ""}
              >
                Eliminar Empleado
              </button>
            )}
            {props.usuario1.nivel !== "2" && (
              <button className="boton" onClick={cerrarLista}>
                Buscar Colaborador
              </button>
            )}
            {props.usuario1.nivel !== "2" && (
              <button
                className={activarOn ? "boton" : "botoncp"}
                onClick={activarOn ? consultarAdjuntos : ""}
              >
                Requisitos Colaborador
              </button>
            )}
            {props.usuario1.nivel !== "2" && (
              <button
                className={activarOn ? "boton" : "botoncp"}
                onClick={activarOn ? datosADM : ""}
              >
                Datos Administrativo
              </button>
            )}
            <button className="boton" onClick={limpiar}>
              Limpiar Datos
            </button>
            {!activarOn && props.usuario1.nivel !== "2" && (
              <button
                className={!activarOn ? "boton" : "botoncp"}
                onClick={!activarOn ? empleadosSusp : ""}
              >
                Empleados Suspendidos
              </button>
            )}
            {/* <button className="boton" onClick={cerrarListaDatosB}>
              Reporte Datos Bancarios
            </button> */}
            <button className="boton" onClick={props.personalClose}>
              Salir
            </button>
          </div>
        )}
        {aprobarOn && (
          <div className="contenedorcedula1 col-12">
            <button
              className={activarOn ? "boton" : "botoncp"}
              onClick={activarOn ? modificarEmpleado2 : ""}
            >
              Modificar Empleado
            </button>
            <button
              className={activarOn ? "boton" : "botoncp"}
              onClick={activarOn ? tipoNominaCreada : ""}
            >
              Tipos de Nominas Creadas
            </button>
            <button
              className={activarOn ? "boton" : "botoncp"}
              onClick={activarOn ? imprimirContrato : ""}
            >
              Imprimir Contrato
            </button>
            <button
              className={activarOn ? "boton" : "botoncp"}
              onClick={activarOn ? horarioNomina : ""}
            >
              Horario Nomina
            </button>
            <button
              className={activarOn ? "boton" : "botoncp"}
              onClick={activarOn ? datosADM : ""}
            >
              Salir Administrativo
            </button>
            {data.aprobado !== "SI" && (
              <button className="boton" onClick={aprobarC}>
                Aprobar Colaborador
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Empleados);
